$sidebar-margins: 10px;
$sidebar-border-radius: 4px;
$sidebar-touch-border: 2px solid rgba(0, 0, 0, 0.2);
$sidebar-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);

$tab-fg: #333;
$tabs-bg: #fff;
$tab-hover-fg: #000;
$tab-hover-bg: #eee;
$tab-active-fg: #fff;
$tab-active-bg: #0074d9;

@import 'base';

.sidebar {
    box-shadow: $sidebar-shadow;

    &.leaflet-touch {
        box-shadow: none;
        border-right: $sidebar-touch-border;
    }

    @media(min-width:$threshold-sm) {
        border-radius: $sidebar-border-radius;

        &.leaflet-touch {
            border: $sidebar-touch-border;
        }
    }
}

.sidebar-left {

    & ~ .sidebar-map .leaflet-left {
        @media(min-width:$threshold-sm) {
            transition: left $sidebar-transition;
        }

        @media(min-width:$threshold-sm) and (max-width:$threshold-md - 1px) {
            left: $width-sm + $sidebar-margins;
        }

        @media(min-width:$threshold-md) and (max-width:$threshold-lg - 1px) {
            left: $width-md + $sidebar-margins;
        }

        @media(min-width:$threshold-lg) {
            left: $width-lg + $sidebar-margins;
        }
    }

    &.collapsed ~ .sidebar-map .leaflet-left {
        @media(min-width:$threshold-sm) {
            left: $tab-size + $sidebar-margins;
        }
    }
}

.sidebar-right {

    & ~ .sidebar-map .leaflet-right {
        @media(min-width:$threshold-sm) {
            transition: right $sidebar-transition;
        }

        @media(min-width:$threshold-sm) and (max-width:$threshold-md - 1px) {
            right: $width-sm + $sidebar-margins;
        }

        @media(min-width:$threshold-md) and (max-width:$threshold-lg - 1px) {
            right: $width-md + $sidebar-margins;
        }

        @media(min-width:$threshold-lg) {
            right: $width-lg + $sidebar-margins;
        }
    }

    &.collapsed ~ .sidebar-map .leaflet-right {
        @media(min-width:$threshold-sm) {
            right: $tab-size + $sidebar-margins;
        }
    }
}
